import { Box } from "@mui/material";

import PageContainerStyle from "./PageContainer.styles";

const LeftBox = ({ children }) => {
  const classes = PageContainerStyle();

  return <Box className={`${classes.leftBox} leftsectionWrapper`}>{children}</Box>;
};
export default LeftBox;
