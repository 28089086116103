import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useAssetFilter = makeStyles((theme: Theme) => ({
  option: {
    "&:hover": {
      backgroundColor: `${theme?.palette.customColor.BGLIGHT} !important`,
    },
  },
  contentcontroler: {
    width: "94.5%",
    maxWidth: "262px ",
    marginLeft: "18px !important",
    wordBreak: "break-all",
  },
  searchinputandor: {
    marginLeft: "10px",
    filter: "brightness(0)",
  },
  option1: {
    "&:hover": {
      backgroundColor: `${theme?.palette.customColor.BGLIGHT} !important`,
    },
  },
  contentcontrol: {
    width: "94.5%",
    maxWidth: "500px",
    wordBreak: "break-all",
  },
  inputadorbox: {
    marginLeft: "10px",
    filter: "brightness(0)",
  },
}));
