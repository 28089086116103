/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import "./Card.css";
import { ImgPlaceholder } from "@platformx/utilities";

type ImageCardProps = {
  data: {
    asset_title: string;
    asset_id: string;
    asset_url: string;
    type: string;
    description: string;
    status: string;
    language: string;
    author: string;
    dateIssued: string;
    collectionId: string;
    collectionName: string;
  };
  isSelected: boolean; // Indicate if the image is selected
  onSelect: (data: any) => typeof data; // Function to handle image selection
};

const ImageCard = ({ data, isSelected, onSelect }: ImageCardProps) => {
  const handleCardClick = () => {
    onSelect(data); // Call the onSelect function with the image data
  };

  return (
    <Box className={`card ${isSelected ? "selected" : ""}`} onClick={handleCardClick}>
      <Box className=''>
        <Box className=''>
          <img
            src={data?.asset_url}
            className='imgbox'
            alt={data?.asset_title}
            onError={(event) => {
              (event.target as HTMLImageElement).src = ImgPlaceholder;
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCard;
