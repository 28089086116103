import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import { useStyles } from "./PreviewTabsButton.styles";
import { WindowIcon, TabletIcon, MobileIcon } from "@platformx/utilities";

const PreviewTabsButton = ({ handleChange, value, previewStatus, iconDisabled }) => {
  const classes = useStyles();
  return (
    <Box className={classes.previewTabsButtons}>
      <TabContext value={value}>
        <TabList
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          onChange={handleChange}>
          <Tab
            icon={<img src={WindowIcon} alt='' />}
            value='window'
            disabled={!previewStatus || iconDisabled}
            className='Icon'
          />
          <Tab
            icon={<img src={TabletIcon} alt='' />}
            value='tablet'
            disabled={!previewStatus || iconDisabled}
            className='Icon'
          />
          <Tab
            icon={<img src={MobileIcon} alt='' />}
            value='mobile'
            disabled={!previewStatus || iconDisabled}
            className='Icon'
          />
        </TabList>
      </TabContext>
    </Box>
  );
};

export default PreviewTabsButton;
