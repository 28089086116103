import "./ButtonEditWindow.css";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchALLContentTypeDetails } from "@platformx/authoring-apis";
import { CircularProgress, MenuItem, OutlinedInput, Select } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

type InternalPageListProps = {
  pageUrl: string;
  dropDownMenuRef: any;
  handleSelectPageUrlChange: any;
};

const userSearch: any = {
  contentType: "Sitepage",
  locationState: {
    searchTerm: "",
  },
  filter: "PUBLISHED",
  startIndex: 0,
  ROW_SIZE: 50,
};
const InternalPageList = (props: InternalPageListProps) => {
  const { t } = useTranslation();
  const { pageUrl = "", dropDownMenuRef, handleSelectPageUrlChange = () => {} } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);

  const handleSelectPageUrlChanges = (e?: any) => {
    const { target: { value = "" } = {} } = e;
    handleSelectPageUrlChange(e);
    const findObj: any = options.find((ele) => ele?.page === value);
    const newObj = JSON.stringify({
      title: findObj?.title,
      page: `/${findObj?.page}`,
      convertFrom: "internalPageUrl",
    });

    setOpen(false);
    handleSelectPageUrlChange({
      target: {
        value: JSON.stringify(newObj),
      },
    });
  };

  const fetchApiCall = async () => {
    setLoading(true);
    try {
      const res = await fetchALLContentTypeDetails(userSearch);
      if ((res?.authoring_getContentTypeItems || []).length > 0) {
        setOptions([...res?.authoring_getContentTypeItems]);
      } else {
        setOptions([]);
      }
    } catch {
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const defaultPageName = (pageName: string) => {
    if (pageName && typeof pageName === "string") {
      if (pageName.includes("internalPageUrl")) {
        const newObj = JSON.parse(JSON.parse(pageName));
        return newObj?.title;
      }
      return pageName;
    }
    return pageName;
  };

  useEffect(() => {
    if (open) {
      fetchApiCall();
    }
  }, [open]);
  return (
    <Select
      variant='filled'
      displayEmpty
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      label='Choose Page'
      value={pageUrl}
      onChange={handleSelectPageUrlChanges}
      placeholder='Choose Page'
      input={<OutlinedInput />}
      sx={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: "25px",
        lineHeight: "28px",
        "& .Platform-x-Select-select": {
          paddingTop: "12.5px",
          paddingBottom: "12.5px",
          maxWidth: `calc(100% - 46px)`,
        },
      }}
      IconComponent={(allprops) =>
        loading ? (
          <CircularProgress
            {...allprops}
            sx={{ color: (theme) => theme.palette?.customColor?.LABEL, padding: "4px" }}
            size={30}
          />
        ) : (
          <ArrowDropDown
            {...allprops}
            sx={{ color: (theme) => theme.palette?.customColor?.LABEL }}
          />
        )
      }
      MenuProps={{ ref: dropDownMenuRef, className: "pageListItems" }}>
      {!open && pageUrl ? (
        <MenuItem key={"0-1"} value={pageUrl}>
          {defaultPageName(pageUrl)}
        </MenuItem>
      ) : null}

      {loading ? (
        <MenuItem sx={{ pointerEvents: "none" }} value='loading'>
          {`${t("loading")}...`}
        </MenuItem>
      ) : (
        options?.map((item, key) => (
          <MenuItem
            key={key}
            value={`${item?.page}`}
            title={item?.title}
            className='internal-page-title-list'>
            {item?.title}
          </MenuItem>
        ))
      )}
    </Select>
  );
};

export default React.memo(InternalPageList);
