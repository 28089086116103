export const prelemIdArray = [
  "Prelem_007",
  "Prelem_023",
  "Prelem_026",
  "Prelem_001",
  "Prelem_006",
  "Prelem_064",
  "Prelem_079",
  "Prelem_061",
  "Prelem_014",
  "Prelem_059",
  "Prelem_055",
  "Prelem_024",
  // "Prelem_045",
  "Prelem_023",
  "Prelem_058",
  "Prelem_002",
  "Prelem_056",
  "Prelem_060",
  "Prelem_017",
  "Prelem_009",
  "Prelem_070",
];
