import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const PrelemSearchHeaderStyle = makeStyles((theme: Theme) => ({
  prelemsearchheader: {
    padding: "10px 15px",
    background: theme.palette.customColor.BGLIGHT,
    minHeight: "63px",
    borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 9,
    justifyContent: "space-between",
  },
}));
export default PrelemSearchHeaderStyle;
