import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  PageSettingMenuTabs: {
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      height: "calc(100vh - 60px)",
      justifyContent: "flex-end",
    },
  },
  emptWpBoxInner: {
    background: theme.palette.customColor.BGWEAK,
    borderRadius: theme.borderRadius.value1,
    width: "100%",
    height: "calc(100vh - 93px)",
    position: "absolute",
    left: "0",
    top: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // margin: "15px",
    "& .EditPageIcons": {
      color: theme.palette.customColor.TERTIARY,
      display: "block",
      padding: 0,
      maxHeight: "50px",
      maxWidth: "50px",
      "& svg": {
        fontSize: "50px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "5px",
      width: "calc(100% - 10px)",
      height: "calc(100vh - 72px)",
    },
  },
  TabButtons: {
    width: "100%",
    border: 0,
    minHeight: "42px !important",
    "& button": {
      padding: 0,
      minHeight: "42px",
      fontSize: "14px",
      textTransform: "capitalize",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        borderBottom: 0,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minWidth: "50%",
      },
      "&:last-child": {
        justifyContent: "center",
      },
      "& img": {
        filter:
          "brightness(0) saturate(100%) invert(75%) sepia(5%) saturate(1021%) hue-rotate(196deg) brightness(88%) contrast(87%)",
        marginRight: "12px",
        [theme.breakpoints.down("sm")]: {
          marginRight: 0,
          marginBottom: "7px",
        },
      },
      "&.Mui-selected": {
        "& img": {
          filter: "brightness(0) saturate(100%) invert(13%) sepia(18%)",
        },
      },
    },
  },
  tabPanelSettingPage: {
    padding: 0,
    height: "calc(100vh - 160px) !important",
  },
  tabButtonsBottom: {
    borderBottom: "0 !important",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "-4px",
      left: 0,
      width: "100%",
      background: theme.palette.customColor.BGLIGHT,
      padding: "10px 15px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      zIndex: "999",
    },
  },
  pageSettingBox: {
    maxHeight: "calc(100vh - 61px)",
    overflowY: "auto",
  },
  addEditPrelemBox: {
    width: "100%",
    textAlign: "center",
    height: "calc(100vh - 63px)",
    padding: "15px",
    overflowX: "hidden",
    background: theme.palette.customColor.BGWEAK,
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0 76px",
    },
    // overflowY: 'scroll',
  },
  addEditPrelemBoxWeb: {
    padding: "15px",
    textAlign: "center",
  },
  innerBoxWeb: {
    width: "100%",
    background: theme.palette.customColor.BGWEAK,
    margin: "auto",
    textAlign: "left",
    padding: "25px 2px 15px",
    borderRadius: theme.borderRadius.value1,
    zIndex: 99,
  },
  addEditPrelemBoxTab: {
    padding: "15px",
    textAlign: "center",
  },
  innerBoxTab: {
    width: "768px",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    borderRadius: theme.borderRadius.value1,
    overflow: "hidden",
    margin: "auto",
    textAlign: "left",
    padding: "2px",
  },
  addEditPrelemBoxMob: {
    padding: "15px",
    textAlign: "center",
  },
  innerBoxMob: {
    width: "360px",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    borderRadius: theme.borderRadius.value1,
    overflow: "hidden",
    margin: "auto",
    textAlign: "left",
    padding: "2px",
  },
  workflowPage: {
    position: "relative",
    height: "100vh",
  },
  previewPage: {
    height: "100vh !important",
  },
}));
