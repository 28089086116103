import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  previewTabsButtons: {
    "& .Icon": {
      width: "63px",
      height: "63px",
      minWidth: "63px !important",
      border: "0 !important",
      padding: "0 !important",
      transition: "all 0.3s",
      "&:hover": {
        backgroundColor: theme.palette.customColor.TERTIARY,
        "& img": {
          filter: "brightness(0) invert(1)",
        },
      },
    },
  },
}));
