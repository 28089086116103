import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const PageContainerStyle = makeStyles((theme: Theme) => ({
  PageContainer: {
    display: "flex",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  leftBox: {
    maxWidth: "300px",
    minWidth: "300px",
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
    height: "calc(100vh - 63px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      borderRight: "0",
      height: "auto",
    },
  },
  logowp: {
    padding: "14px 15px",
    borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
    minHeight: "60px",
    "& img": {
      cursor: "pointer",
    },
  },
  img: {
    cursor: "pointer",
  },
  rightBox: {
    width: "100%",
    height: "calc(100vh - 63px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    "& .rightHeaderWp": {
      padding: "13px 15px 7.5px",
      display: "inline-block",
      width: "100%",
    },
  },
}));

export default PageContainerStyle;
