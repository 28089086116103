import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    prelemSEOWrapper: {
      "&.prelemSEOWrapperBg": {
        "& .prelemSEOStructureDataOuter": {
          position: "fixed",
          width: "100%",
          left: 0,
          top: 0,
          zIndex: "999",
          height: "100%",
          [theme.breakpoints.up("xs")]: {
            padding: "20px",
          },
          [theme.breakpoints.up("md")]: {
            padding: "40px",
          },
          [theme.breakpoints.up("lg")]: {
            padding: "60px",
          },
          backgroundColor: `rgba(0,0,0,0.3)`,
        },
        "& .prelemSEOStructureDataInner": {
          background: "#fff",
          [theme.breakpoints.down("sm")]: {
            width: "98%",
          },
          [theme.breakpoints.up("sm")]: {
            width: "80%",
          },
          margin: "0px auto",
          padding: "20px",
          height: "100%",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px 0px",
          borderRadius: "8px",
        },
        "& .buttonWrapper": {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "20px",
          padding: "0 20px",
        },
      },
    },
    pageInfoWrapper: {
      "&.rowBox.customtags": {
        "& ul": {
          padding: 0,
          listStyleType: "none",
          "& li": {
            border: `1px solid  ${theme.palette.customColor.PRIMARY}`,
            margin: "10px 10px 0 0",
            display: "inline-flex",
            padding: "6px 12px",
            alignItems: "center",
            borderRadius: theme.borderRadius.value,
            justifyContent: "center",
          },
        },
      },
    },
  };
});
