import { makeStyles } from "@mui/styles";
import { LeftArrowIcon, RightArrowIcon } from "@platformx/utilities";

export const CardCustomStyle = makeStyles(() => {
  return {
    dashboardCardSlider: {
      "&.CardSliderImageUpload": {
        width: "calc(100% - 110px)",
        "& .slick-arrow.slick-prev": {
          "&:before": {
            content: `''`,
            textIndent: 0,
            width: "11px",
            height: "16px",
            filter: "brightness(0) invert(1)",
            opacity: 1,
            backgroundImage: `url(${LeftArrowIcon})`,
            margin: 0,
          },
        },
        "& .slick-arrow.slick-next": {
          "&:before": {
            content: `''`,
            textIndent: 0,
            width: "11px",
            height: "16px",
            filter: "brightness(0) invert(1)",
            opacity: 1,
            backgroundImage: `url(${RightArrowIcon})`,
            margin: 0,
          },
        },
      },
    },
  };
});
