import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useCustomStyle = makeStyles((theme: Theme) => ({
  switchBoxContainer: {
    "& .switchRootClass": {
      margin: "0px 0px 0px 10px",
      paddingRight: "25px",
    },
  },
  seoBasicsWrapper: {
    "&.seoBasics": {
      minHeight: "48px",
      border: `1px solid  ${theme.palette.customColor.LINE}`,
      borderRadius: theme.borderRadius.value,
      padding: "14px 20px 14px 20px",
    },
  },
}));
