import { Box } from "@mui/material";
import { contentTypeAPIs } from "@platformx/authoring-apis";
import { SORT_ORDER, debounce } from "@platformx/utilities";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { categoryData, contentPaths } from "../../../../../utils/constant";
import AdvanceFilter from "./AdvanceFilter";
import AllCatCta from "./AllCatCta";
import AutoCompleteSearch from "./AutoCompleteSearch";

type FiltersObj = {
  tags?: string[];
  author?: string;
  fromDate?: string;
  toDate?: string;
};

export default function AdvanceSearchBar({ handleClose }) {
  const [selectedCategory, setSelectedCategory] = useState({
    icon: categoryData[0].icon,
    title: categoryData[0].title,
    category: categoryData[0].category,
  });
  const [filtersObj, setFiltersObj] = useState<FiltersObj>({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [autoCompleteData, setAutoCompleteData] = useState<any>([]);
  const setCategory = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    setFiltersObj(() => filtersObj);
  }, [filtersObj]);

  const handleFilters = (obj) => {
    setFiltersObj(obj);
  };

  const getSuggestions = async (searchTerm) => {
    try {
      if (searchTerm) {
        setLoading(true);
        const response = await contentTypeAPIs.fetchContentAll({
          contentType: selectedCategory.category,
          pageFilter: "ALL",
          sort: SORT_ORDER,
          searchTerm: searchTerm,
          isSuggestive: true,
          pagination: { start: 0, rows: 10 },
          tags: filtersObj.tags,
          author: filtersObj.author,
          fromDate: filtersObj.fromDate,
          toDate: filtersObj.toDate,
        });
        const itemsList = response?.authoring_getContentTypeItems || [];
        setAutoCompleteData([...itemsList]);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestions = debounce((value) => getSuggestions(value), 1000);

  const handleSearchKeyword = useCallback((value) => {
    setSearchKeyword(value);
    handleSuggestions(value);
  }, []);

  const getDateFormat = (date) => {
    const newDate = new Date(date);
    const newDate2 =
      newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;

    return `${newDate.getFullYear()}-${newDate2}-${newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()}`;
  };
  const handleSearch = (filters) => {
    const path = contentPaths.find((o) => o.ContentType === selectedCategory.category);
    if (path) {
      localStorage.setItem("contentType", selectedCategory.title);
      localStorage.setItem("searchKeyword", searchKeyword);
      localStorage.setItem("searchTags", filters.tags);
      localStorage.setItem("author", filters.author);
      navigate(path.Url, {
        state: {
          searchTerm: searchKeyword,
          tags: filters.tags,
          author: filters.author,
          fromDate: filters.fromDate && getDateFormat(filters.fromDate),
          toDate: filters.toDate && getDateFormat(filters.toDate),
        },
      });
    } else {
      // Handle the case where 'path' is undefined
      console.error("Path is undefined");
    }

    handleClose();
  };

  return (
    <Box className='advSearch' component='form'>
      <Box className='leftCol'>
        <AllCatCta setCategory={setCategory} />
        <Box className='searchwp'>
          <AutoCompleteSearch
            handleSearchKeyword={handleSearchKeyword}
            handleSearch={handleSearch}
            loading={loading}
            autoCompleteData={autoCompleteData}
          />
        </Box>
      </Box>
      <AdvanceFilter handleFilters={handleFilters} handleSearchData={handleSearch} />
    </Box>
  );
}
