import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  plusIconBox: {
    background: theme.palette.customColor.BGLIGHT,
    boxShadow: "0 10px 25px 0 rgba(0, 0, 0, 0.12)",
    position: "relative",
    top: "18px",
    left: "20px",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "auto",
      bottom: "12%",
      left: "auto",
      right: "5%",
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBoxCta: {
    [theme.breakpoints.down("sm")]: {
      border: `1px solid ${theme.palette.customColor.LINE}`,
      boxShadow: "0px 25px 30px rgba(0, 0, 0, 0.05)",
      margin: "5px 3px",
      background: theme.palette.customColor.BGLIGHT,
    },
  },
  trayStyle: {
    backgroundColor: "white",
    borderRadius: theme.borderRadius.value,
    boxShadow: "6px 16px 17px 0 rgba(206, 211, 217, 0.67)",

    [theme.breakpoints.up("lg")]: {
      marginLeft: "10px",
      zIndex: 1,
      position: "unset",
      right: "unset",
      bottom: "unset",
      display: "flex",
      flexDirection: "row",
    },

    [theme.breakpoints.up("xs")]: {
      zIndex: 99,
      position: "fixed",
      right: " 5%",
      bottom: " 13%",
      display: "flex",
      flexDirection: "column",
    },
  },
}));
