import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ButtonEditWindowStyle = makeStyles((theme: Theme) => ({
  buttonEditWindowWp: {
    "& .boxWarp": {
      backgroundColor: theme.palette.customColor.BGLIGHT,
      padding: "30px",
      borderRadius: theme.borderRadius.value,
      boxSizing: "border-box",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: `translate(-50%,-50%)`,
      border: `1px solid ${theme.palette.customColor.LINE}`,
    },
    "& .contentWarper": {
      position: "relative",
      border: `1px solid ${theme.palette.customColor.LINE}`,
      width: "75%",
      height: "48px",
      borderRadius: theme.borderRadius.value,
      padding: "12px 15px 12px 15px",
      backgroundColor: theme.palette.customColor.BGLIGHT,
      "& .inputWp": {
        whiteSpace: "nowrap",
        width: "100%",
        backgroundColor: theme.palette.customColor.BGLIGHT,
        alignItems: "center",
        display: "flex",
        justifyContent: "start",
        "& p": {
          width: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          whiteSpace: "normal",
        },
      },
    },
    "& .rightblackbox": {
      height: "40px",
      lineHeight: "40px",
      borderRadius: theme.borderRadius.value,
      alignItems: "center",
      backgroundColor: theme.palette.customColor.PRIMARY,
      justifyContent: "center",
      display: "flex",
      "& img": {
        width: "20px",
        height: "20px",
        cursor: "pointer",
        objectFit: "contain",
      },
    },
  },
}));

export default ButtonEditWindowStyle;
