import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteIcon, ShowToastError } from "@platformx/utilities";
import { useEffect, useState } from "react";
import { getMaxId } from "../../helper";

const FormDropDownSetting = ({ selectedElement, updateElement, renderValidation }) => {
  const [nextId, setNextId] = useState(getMaxId(selectedElement?.options) + 1);
  const [selectedValue, setSelectedValue] = useState(selectedElement?.default_value?.[0] || "");

  useEffect(() => {
    setSelectedValue(selectedElement?.default_value?.[0]);
  }, [selectedElement?.default_value]);

  useEffect(() => {
    // Reset nextId based on the highest id in options whenever selectedElement changes
    setNextId(getMaxId(selectedElement?.options) + 1);
  }, [selectedElement]);

  const handleChange = (field, val) => {
    updateElement(selectedElement.id, { [field]: val });
  };
  const handleAddOption = () => {
    setNextId((id) => id + 1);
    const newOption = {
      label: `Option ${nextId}`,
      value: `Option ${nextId}`,
      id: nextId,
      parentId: 0,
    };
    updateElement(selectedElement?.id, {
      options: [...selectedElement?.options, newOption],
    });
  };

  const handleOptionChange = (key: string, index: number, val: string) => {
    const newOptions = [...selectedElement?.options];
    newOptions[index] = {
      ...newOptions[index],
      [key]: val,
    };
    updateElement(selectedElement?.id, {
      options: newOptions,
    });
  };

  const handleRemoveOption = (index: number) => {
    if (selectedElement?.options?.length > 1) {
      const newOptions = selectedElement.options.filter((_, i) => i !== index);
      //if default value is removed then set defualt value from top
      let newDefaultValue = selectedElement.default_value;
      const removedOption = selectedElement.options?.[index]?.label;
      if (newDefaultValue.includes(removedOption)) {
        newDefaultValue = newOptions.length > 0 ? [newOptions[0].label] : [];
      }
      setSelectedValue(newDefaultValue);
      updateElement(selectedElement?.id, {
        options: newOptions,
        default_value: newDefaultValue,
      });
    } else {
      ShowToastError("At least one option is required");
    }
  };
  const handleDefaultValue = (event: any) => {
    const { value } = event.target;
    setSelectedValue(value);
    updateElement(selectedElement.id, { default_value: [value] });
  };

  return (
    <Box className='formDropdown elementWrapper'>
      <Typography variant='h5semibold'>Dropdown Setting</Typography>
      <Box mt={1.5} mb={1.5}>
        <Divider />
      </Box>
      <Box mt={1}>
        <Typography variant='p4regular'>Title Text</Typography>
        <TextField
          fullWidth
          margin='dense'
          variant='outlined'
          value={selectedElement?.title}
          onChange={(e) => handleChange("title", e?.target?.value)}
        />
      </Box>
      <Box mt={1.5}>
        <Typography variant='p4regular'>Add Options</Typography>
      </Box>
      <Box mt={1}>
        <Box mt={1.5} sx={{ display: "flex" }}>
          <Typography variant='p4regular' sx={{ width: "46%" }}>
            Label
          </Typography>
          {/* <Typography variant='p4regular'>Value</Typography> */}
        </Box>
        {selectedElement?.options?.map((opt, index) => (
          <Box className='dropdownItemList' key={index}>
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              value={opt?.label}
              onChange={(e) => handleOptionChange("label", index, e.target.value)}
              sx={{ maxWidth: "100%", marginRight: "1%" }}
            />
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              value={opt?.value}
              onChange={(e) => handleOptionChange("value", index, e.target.value)}
              sx={{ maxWidth: "46%", marginLeft: "1%", display: "none" }}
            />
            <IconButton
              onClick={() => handleRemoveOption(index)}
              data-testid='dropdown-remove-option'
              sx={{ width: "33px", height: "33px", margin: "15px 5px" }}>
              <img src={DeleteIcon} alt='delete' style={{ width: "30px" }} />
            </IconButton>
          </Box>
        ))}
        <Box mt={2}>
          <Button
            variant='primaryButton'
            onClick={handleAddOption}
            className='sm'
            data-testid='dropdown-add-option'>
            Add Option
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant='p4regular' id='default-simple-select-label'>
            Select Default Value
          </Typography>
          <Select
            fullWidth
            labelId='default-simple-select-label'
            id='default-simple-select-label'
            value={selectedValue}
            name={"selectedDefaultValue"}
            onChange={handleDefaultValue}
            sx={{ mt: 1, mb: 2 }}>
            {selectedElement?.options?.map((item) => (
              <MenuItem key={item?.label} value={item?.label}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {selectedElement?.validations?.length > 0 && (
          <Box className='validationSection' mt={2}>
            <Typography variant='h6semibold'>Validation</Typography>
            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>
            {selectedElement?.validations?.map((validationType) =>
              renderValidation(validationType),
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FormDropDownSetting;
