/* eslint-disable no-unused-vars */
import { KeyboardBackspace, SaveOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { RootState, setIsCommentPanelOpen, setIsReviewEnabled } from "@platformx/authoring-state";
import {
  CATEGORY_PAGE,
  DefaultStateCommentIcon,
  ErrorTooltip,
  SUB_CATEGORY_PAGE,
  SubmitButton,
  Timer,
  enableReferBack,
  useAccess,
} from "@platformx/utilities";
import { WorkflowHistoryIcon } from "@platformx/workflow-management";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./Header.styles";

const HeaderMobile = ({
  lastmodifiedDate,
  handleBack,
  isSaveButtonEnabled,
  handleSaveClick,
  handlePublishClick,
  timerState,
  prelemEditState,
  workflow,
  setEnableWorkflowHistory,
  loader,
}) => {
  const classes = useStyles();
  const { canAccessAction } = useAccess();
  const { isReviewEnabled, comments } = useSelector(
    (state: RootState) => state.comment.commentInfo,
  );
  const dispatch = useDispatch();

  const handleReview = () => {
    dispatch(setIsReviewEnabled(!isReviewEnabled));
    dispatch(setIsCommentPanelOpen({ value: true }));
  };
  useEffect(() => {
    if (enableReferBack(workflow) || comments?.length > 0) {
      dispatch(setIsReviewEnabled(true));
    } else {
      dispatch(setIsReviewEnabled(false));
    }
  }, [isReviewEnabled, workflow, comments]);
  return (
    <Box className={`${classes.headerwp} ${classes.mobHeader}`}>
      <Box className={classes.leftwp}>
        <KeyboardBackspace onClick={handleBack} />
      </Box>
      <Box sx={{ display: "flex" }}>
        {comments?.length > 0 ? (
          <Box sx={{ position: "relative" }} className={classes.buttonWrapper}>
            <span color='error' className='errorcls'></span>
            <Button
              className='iconBtn'
              onClick={handleReview}
              startIcon={<img src={DefaultStateCommentIcon} width='20px' alt='' />}></Button>
          </Box>
        ) : (
          enableReferBack(workflow) && (
            <Box className={classes.buttonWrapper}>
              <Button
                className='iconBtn'
                onClick={handleReview}
                startIcon={<img src={DefaultStateCommentIcon} width='20px' alt='' />}></Button>
            </Box>
          )
        )}
        {timerState && <Timer lastmodifiedDate={lastmodifiedDate} />}
        <WorkflowHistoryIcon
          enableWorkflowHistory={setEnableWorkflowHistory}
          workflow_status={workflow?.workflow_status}
        />
        <ErrorTooltip
          className={classes.buttonWrapper}
          component={
            <Button
              className='iconBtn'
              startIcon={<SaveOutlined />}
              disabled={!isSaveButtonEnabled || prelemEditState}
              onClick={handleSaveClick}></Button>
          }
          doAccess={!canAccessAction(CATEGORY_PAGE, SUB_CATEGORY_PAGE, "Create")}
        />
        {/* <ErrorTooltip
          component={
            <Button
              sx={{ minWidth: 'auto' }}
              disabled={!isPublishButtonEnabled || prelemEditState}
              onClick={handlePublishClick}
            >
              <NearMeOutlined />
            </Button>
          }
          doAccess={!canAccessAction(CATEGORY_PAGE, '', 'publish')}
        /> */}
        <Box sx={{ marginLeft: "12px" }}>
          <SubmitButton
            category={CATEGORY_PAGE}
            subCategory={SUB_CATEGORY_PAGE}
            handlePublish={handlePublishClick}
            handleSave={handleSaveClick}
            workflow={workflow}
            prelemEditState={prelemEditState}
            publishDisabled={loader}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderMobile;
