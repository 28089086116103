import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Tag_Icon, isSubsequence } from "@platformx/utilities";
import { t } from "i18next";
import { useTagMenuStyle } from "./TagMenu.style";

const TagMenu = ({ label, tag, setTag, searchQuery }) => {
  const classes = useTagMenuStyle();

  const TAG = searchQuery
    ? label?.tag?.filter((obj) => isSubsequence(searchQuery, obj.label.toLowerCase()))
    : label?.tag;

  const onChange = (value, tagSelected) => {
    const updatedSelectedItems = tagSelected
      ? tag.filter((item) => item !== value)
      : [...tag, value];

    setTag(updatedSelectedItems);
  };

  return (
    <Grid item xs={12} sm={4} md={12} lg={12}>
      <Box className={classes.tagboxn}>
        <Accordion className={classes.boxshadow} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1-content'
            id='panel1-header'>
            <img src={Tag_Icon} alt='' />
            <Box className={classes.typospace}>
              <Typography variant='h6semibold'>{t("tags")}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.tagaccordiandeatail}>
            {TAG?.map((obj) => {
              const tagSelected = tag.includes(obj.label);
              return (
                <Box key={obj.label} className={classes.tagmockval}>
                  <Button
                    style={{ fontWeight: 400 }}
                    className={classes.tagbtn}
                    onClick={() => onChange(obj.label, tagSelected)}
                    variant={tagSelected ? "primaryButton" : "secondaryButton"}>
                    {obj.label}
                  </Button>
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
};

export default TagMenu;
