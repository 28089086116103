import { FormControl, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Profile, capitalizeFirstLetter, isSubsequence } from "@platformx/utilities";
import useDamContent from "./DamContent.style";

// Component to render an author filter list
export function AuthorMenu({ setAuthor, label, author, searchQuery }) {
  const classes = useDamContent(); // Applying custom styles

  const AUTHOR = searchQuery
    ? label?.author?.filter((obj) => isSubsequence(searchQuery, obj.label.toLowerCase()))
    : label?.author;

  // Handler to update the selected author
  const handleSelect = (selectedAuthor) => {
    setAuthor(selectedAuthor);
  };

  return (
    <FormControl variant='outlined' className={classes.selectRoot}>
      <List>
        {AUTHOR?.map((obj) => (
          <ListItem
            className={classes.listitembox}
            button
            key={obj.label}
            onClick={() => handleSelect(obj.label)}
            selected={author === obj.label}>
            <ListItemIcon className={classes.authoricon}>
              <img style={{ filter: "invert(100%) brightness(0)" }} src={Profile} alt='profile' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='p3medium'>
                {capitalizeFirstLetter(obj.label?.toLowerCase())}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </FormControl>
  );
}
