import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./app/app";

const initiateSentry = () => {
  Sentry.init({
    dsn: "https://db67c5673015f2207e4617a703ee86ed@o4507106952019968.ingest.de.sentry.io/4507130673102928",
    environment: process.env.NX_PUBLIC_SENTRY_ENVIRONMENT,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

if (process.env.NX_PUBLIC_SENTRY_ENABLED === "true") {
  // eslint-disable-next-line no-console
  console.log("Sentry enabled", process.env.NX_PUBLIC_SENTRY_ENABLED);
  initiateSentry();
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
