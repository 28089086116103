import { Box, Typography } from "@mui/material";
import { NoAssetSelected } from "../../assets/svg";
import { useTranslation } from "react-i18next";
import { useAssetUpload } from "./AssetUpload.style";

// component to empty screen, when user lands on file upload screen
const NoFileSelected = () => {
  const { t } = useTranslation();
  const classes = useAssetUpload();
  return (
    <>
      <Box className='assetboxone'>
        <img src={NoAssetSelected} alt='No asset' />
      </Box>
      <Typography className={classes.typography} variant='h5bold'>
        {t("there_are_no")}
      </Typography>
      <Typography className={classes.typography} variant='h6medium'>
        {t("please_upload")}
      </Typography>
    </>
  );
};

export default NoFileSelected;
