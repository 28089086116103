import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  pageSettingWp: {
    padding: "0",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.customColor.PRIMARY,
    justifyContent: "space-between",
    margin: "12px 0",
    cursor: "pointer",
    padding: "5px 0",
    "&:hover": {
      color: theme.palette.customColor.TERTIARY,
      "& h6": {
        color: theme.palette.customColor.TERTIARY,
      },
    },
    "&:hover img": {
      filter:
        "invert(58%) sepia(98%) saturate(2289%) hue-rotate(188deg) brightness(100%) contrast(96%)",
    },
  },
  leftwp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  imgbox: {
    display: "flex",
    width: "16px",
    marginRight: "20px",
  },
}));
