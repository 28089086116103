export const Dashboard_Keys = {
  SITE_PAGE: "Sitepage",
  DASHBOARD: "dashboard",
  BOOST_PAGE: "boostpage",
  SCHEDULED_PUBLISH: "SCHEDULED_PUBLISH",
  SCHEDULED_UNPUBLISH: "SCHEDULED_UNPUBLISH",
  DESC: "DESC",
  ALL: "ALL",
  ZERO: 0,
};
