import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const PrelemListStyle = makeStyles((theme: Theme) => ({
  prelemboxwp: {
    border: `1px solid ${theme.palette.customColor.LINE}`,
    width: "calc(50% - 15px)",
    margin: "7.5px",
    display: "inline-flex",
    maxHeight: "300px",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    transition: "all 0.5s",
    float: "left",
    borderRadius: theme.borderRadius.value,
    [theme.breakpoints.down("md")]: {
      maxHeight: "145px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "190px",
    },
    [theme.breakpoints.down("em")]: {
      width: "calc(100% - 15px)",
    },
    "& img": {
      height: "300px",
      margin: "auto",
      maxWidth: "100%",
      objectFit: "contain",
      [theme.breakpoints.down("md")]: {
        height: "145px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "190px",
      },
    },
  },
  overlay: {
    position: "absolute",
    left: "0",
    top: "0",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.70) 4.41%, rgba(55, 55, 62, 0.56) 52.11%, rgba(55, 55, 62, 0.54) 61.65%, rgba(45, 45, 57, 0.00) 99.81%)",
    width: "100%",
    height: "100%",
    color: "#fff",
    display: "flex",
    alignItems: "flex-end",
    textAlign: "left",
    transition: "all 0.5s",
    opacity: 0,
    [theme.breakpoints.down("sm")]: {
      opacity: 1,
    },
    "&:hover": {
      opacity: 1,
    },
  },
  contentwp: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "22px",
    "& h5, p": {
      color: theme.palette.customColor.BGLIGHT,
    },
  },
  listBox: {
    marginLeft: "-7.5px",
    marginRight: "-7.5px",
    marginTop: "7.5px",
  },
  buttonswp: {
    marginTop: "12px",
  },
  linetrancate2line: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  iButton: {
    zIndex: 1,
    borderRadius: "5px",
    width: "34px",
    height: "34px",
    margin: "10px",
    display: "none",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.12)",
    top: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  iconButton: {
    padding: "5px",
  },
}));
export default PrelemListStyle;
