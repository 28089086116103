import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { ButtonProps as MuiButtonProps, styled } from "@mui/material";

// Only include variant, size, and color
type ButtonBaseProps = Pick<MuiButtonProps, "variant">;

export interface ButtonProps extends ButtonBaseProps {
  label?: string;
  variant:
    | "primaryButton"
    | "secondaryButton"
    | "primaryButtonLight"
    | "secondaryButtonLight"
    | "tertiaryButton"
    | "quaternaryButton";
  loading?: boolean;
  loadingPosition?: "start" | "end" | "center";
  startIcon?: boolean;
  endIcon?: boolean;
  disabled?: any;
  onClick?: any;
  className?: string;
  style?: any;
}

const StyledButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  position?: string;
}>(({ position, theme }) => ({
  fontFamily: theme?.fontFamily?.primary,
  ".MuiLoadingButton-loadingIndicator": {
    position: position === "center" ? "absolute" : "initial",
    ...(position === "start" && {
      paddingRight: "8px",
    }),
    ...(position === "end" && {
      paddingLeft: "8px",
    }),
  },
}));
export const XButton = ({
  label,
  variant,
  loading = false,
  loadingPosition,
  startIcon,
  endIcon,
  disabled,
  className,
  onClick,
  style,
  ...rest
}: ButtonProps) => (
  <>
    {/* <style>{fontStyle}</style> */}
    <StyledButton
      position={loadingPosition}
      variant={variant}
      loading={loading}
      loadingPosition={loadingPosition}
      startIcon={startIcon && <SaveIcon />}
      endIcon={endIcon && <SaveIcon />}
      disabled={disabled}
      onClick={onClick}
      className={className}
      style={style}
      {...rest}>
      {label}
    </StyledButton>
  </>
);
