import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ButtonEditLinkStyle = makeStyles((theme: Theme) => ({
  buttonEditLinkWp: {
    backgroundColor: theme.palette.customColor.BGLIGHT,
    padding: "9px",
    borderRadius: `7px`,
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
    width: "100px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "fixed",
    "& .editicon": {
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
    "& .insertLink": {
      width: "20px",
      height: "20px",
      cursor: "pointer",
      transform: `rotate(-45deg)`,
    },
  },
}));

export default ButtonEditLinkStyle;
