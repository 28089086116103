import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { SearchIcon, debounce, handleLogout } from "@platformx/utilities";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./BlogSearchBox.css";

interface Content {
  title: string;
}

function BlogSearchBox({ style, onSearch, sortOrder, sendShowFlag, list = [], getBlogs }: any) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [options, setOptions] = useState<Content[]>([]);
  const loading = open && options.length === 0;
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        try {
          const props = {
            start: 0,
            rows: 20,
            sortOrder: sortOrder,
            isSuggestive: true,
            pageSearch: inputValue,
          };
          const apiResponse = await getBlogs(props);
          if (apiResponse?.length > 0) {
            setOptions(
              apiResponse.map((item: any) => {
                return { title: item?.title };
              }),
            );
          } else {
            setOptions([]);
            setOpen(false);
          }
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
            console.error("logout...from axios");
            handleLogout();
          }
          setOptions([]);
          setOpen(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const emptyOptions = () => {
    setOptions([]);
  };

  const debouncedCall = useCallback(debounce(emptyOptions, 500), []);

  const onInputChange = (_: any, value: any) => {
    setInputValue(value);
    debouncedCall();
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSearch(inputValue);
    }
  };

  const onSelect = (_: any, value: any) => {
    if (value) onSearch(value);
  };

  const resetSearch = () => {
    onSearch("");
    setInputValue("");
  };

  const searchIconClicked = () => {
    setShowSearch(!showSearch);
    sendShowFlag(showSearch);
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: showSearch ? "none" : "block", md: "none" },
          marginRight: "13px",
        }}>
        <img
          alt='searchicon'
          src={SearchIcon}
          style={{ verticalAlign: "middle", cursor: "pointer" }}
          onClick={searchIconClicked}
          data-testid='blog-search'
        />
      </Box>
      <Autocomplete
        id='asynchronousSearch'
        freeSolo
        forcePopupIcon={false}
        sx={{ display: { xs: showSearch ? "block" : "none", md: "block" } }}
        style={style}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disabled={list.length === 0 ? true : false}
        inputValue={inputValue}
        value={inputValue}
        onInputChange={onInputChange}
        onChange={onSelect}
        onKeyDown={onEnter}
        filterOptions={(x) => x}
        options={options.map((option) => (option.title ? option.title : ""))}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`${t("searchBlog")}...`}
            sx={{
              ".Platform-x-InputBase-root": {
                height: "48px",
                fontSize: "14px",
                minHeight: "inherit",
                marginLeft: "0px",
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
                fieldset: {
                  borderColor: "transparent",
                },
                ".Platform-x-InputBase-input": {
                  textTransform: "capitalize",
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: <img alt='searchicon' src={SearchIcon} />,
              endAdornment: (
                <InputAdornment position='end'>
                  {inputValue && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <CloseRoundedIcon
                        onClick={resetSearch}
                        data-testid='reset-blog-search'
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
}

export default BlogSearchBox;
