import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  headerwp: {
    borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 15px",
    position: "static",
    minHeight: "63px",
    maxHeight: "63px",
    background: theme.palette.customColor.BGLIGHT,
    "& .Hdevider": {
      background: theme.palette.customColor.LINE,
      width: "1px",
      height: "36px",
      margin: "0 8px",
    },
    "& .leftHeaderBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      "& .leftLogo": {
        borderRight: `1px solid ${theme.palette.customColor.LINE}`,
        height: "63px",
        marginLeft: "-15px",
        display: "flex",
        alignItems: "center",
        padding: "15px",
        [theme.breakpoints.up("md")]: {
          maxWidth: "300px",
          minWidth: "300px",
        },
      },
    },
  },
  leftwp: {
    minWidth: "125px",
    marginLeft: "15px",
    [theme.breakpoints.up("lg")]: {
      minWidth: "343px",
    },
    "& svg": {
      cursor: "pointer",
    },
  },
  rightwp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    whiteSpace: "nowrap",
  },
  mobHeader: {
    paddingLeft: 0,
    minHeight: "63px",
  },
  timer: {
    display: "flex",
    alignItems: "center",
  },
  buttonWrapper: {
    "& .errorcls": {
      display: "inline-block",
      marginLeft: " 5px",
      marginRight: "14px",
      marginBottom: "-2px",
      borderRadius: "50%",
      position: "absolute",
      top: "7px",
      right: "3px",
      zIndex: 9,
      height: "8px",
      width: "8px",
      color: theme.palette.notification.ERROR.BACKGROUND,
    },
    "& .iconBtn": {
      minWidth: "0px",
      display: "flex",
      justifyContent: "center",
      width: "38px",
      height: "38px",
      alignItems: "center",
      margin: "0 1px",
      "& span": {
        margin: 0,
      },
      "&:hover": {
        backgroundColor: "rgba(20, 20, 43, 0.04)",
      },
      "&:disabled": {
        color: theme.palette.customColor.PLACEHOLDER,
        pointerEvents: "auto",
      },
    },
  },
}));
