import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ShowToastSuccess } from "@platformx/utilities";
import React, { useState } from "react";
import BackButton from "../BackButton/BackButton";
import "../PageSettings/PageSettings.css";

interface Props {
  index: string;
  RpiKey: string;
  handleSave(sectionToUpdate: string, data: SavedData, index: string): void;
  sectionToUpdate?: string;
  setPageId: () => void;
}

interface SavedData {
  rpiKey: string;
}

const CdpFilter: React.FC<Props> = ({
  index,
  RpiKey,
  handleSave,
  setPageId,
  sectionToUpdate = "rpikeyupdate",
}) => {
  const [rpiKey, setRpiKey] = useState(RpiKey);
  const { t } = useTranslation();

  const savedData = {
    rpiKey: rpiKey,
  };

  return (
    <Box key={`${index}_content`} className='pageSettingmainWp'>
      <Box className='rowBox'>
        <BackButton setPageId={setPageId} Title={t("prelem_cdp_filter")} backTo='prelemSetting' />
      </Box>
      <Box className='rowBox'>
        <Typography className='labelbox' variant='p4regular'>
          {t("cdpfilter")}
        </Typography>
        <TextField
          fullWidth
          value={rpiKey}
          error={rpiKey?.length === 0}
          onChange={(e: any) => setRpiKey(e.target.value)}
          variant='filled'
          size='small'
          placeholder='Rpi Key'
          inputProps={{ maxLength: 50 }}
        />
      </Box>
      <Box className='rowBox'>
        <Button
          variant='primaryButton'
          disabled={rpiKey?.length === 0}
          sx={{ width: "100%" }}
          onClick={() => {
            handleSave(sectionToUpdate, savedData, index);
            ShowToastSuccess(`${t("cdp_filter_info_toast")} ${t("saved_toast")}`);
          }}>
          {t("done")}
        </Button>
      </Box>
    </Box>
  );
};

export default CdpFilter;
