import { useApolloClient } from "@apollo/client";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { fetchPageModel } from "@platformx/authoring-apis";
import {
  DraftStatusIcon,
  PlatFormXDateTimeFormat,
  PublishedStatusIcon,
  capitalizeWords,
  formatContentTitle,
  getSubDomain,
} from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RecentPage } from "../recentPages/RecentPages.types";
import { useStyles } from "./PageRow.styles";

const PageRow = ({
  title,
  current_page_url,
  status,
  last_modification_date,
  last_modified_by,
  path,
}: RecentPage) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = useApolloClient();

  // function to view published pages
  const handleClick = (pageStatus: string) => {
    if (pageStatus === "published") {
      window.open(`${getSubDomain()}/${i18n.language}${current_page_url}`);
    } else {
      fetchPageModel(dispatch, client, path, navigate);
    }
  };

  return (
    <Box className={classes.contentRowContainer}>
      <Grid container>
        <Grid
          xs={12}
          md={6}
          sx={{ alignItems: "center", display: "flex", order: { xs: 2, md: "inherit" } }}>
          <Typography
            className={classes.contentRowText}
            variant='h6regular'
            onClick={() => handleClick(status)}
            data-testid='dashboard-page-row'>
            {capitalizeWords(formatContentTitle(title))}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          md={3}
          sx={{
            alignItems: "center",
            order: { xs: 3, md: "inherit" },
          }}>
          <Typography variant='p4regular'>{last_modified_by}</Typography>
          <Typography variant='p4regular'>
            {PlatFormXDateTimeFormat(last_modification_date)}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={3}
          em={2}
          sx={{
            paddingLeft: { xs: 0, md: "15px" },
            order: { xs: 1, md: "inherit" },
            marginBottom: { xs: "5px", md: 0 },
            alignItems: "center",
            display: "flex",
          }}>
          <Box>
            {status === "published" ? (
              <Tooltip title={t(status)} placement='top-end'>
                <img src={PublishedStatusIcon} alt={t(status)} />
              </Tooltip>
            ) : (
              <Tooltip title={t(status)} placement='top-end'>
                <img src={DraftStatusIcon} alt={t(status)} />
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={1}
          sx={{
            display: { xs: "none", em: "flex" },
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Box
            className={`${classes.arrowIcon} ${classes.arrowIcon}`}
            onClick={() => handleClick(status)}
            data-testid='dashboard-page-row-2'>
            <Box className='wraperIcon'>
              <EastRoundedIcon fontSize='medium' />
              <EastRoundedIcon fontSize='medium' />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageRow;
