import {
  Article,
  Awards,
  Challenges_community,
  CommunityIcon,
  contentDefault,
  convertToLowerCase,
  Course,
  EventIcons,
  Faq,
  FeatureCard,
  Formbuilder30,
  General_community,
  imgIcon,
  News_community,
  Polls,
  Profile,
  quizIcon,
  ServiceCard,
  Shopping_bag,
  Testimonial,
  vodIcon,
} from "@platformx/utilities";

export const getIcon = (ct: string) => {
  switch (convertToLowerCase(ct)) {
    case "article":
      return Article;
    case "poll":
      return Polls;
    case "quiz":
      return quizIcon;
    case "imagegallery":
      return imgIcon;
    case "event":
      return EventIcons;
    case "faq":
      return Faq;
    case "testimonial":
      return Testimonial;
    case "awards":
      return Awards;
    case "servicecard":
      return ServiceCard;
    case "accolades":
      return FeatureCard;
    case "product":
      return Shopping_bag;
    case "course":
      return Course;
    case "news":
      return News_community;
    case "general":
      return General_community;
    case "challenges-announcement":
      return Challenges_community;
    case "community":
      return CommunityIcon;
    case "profile":
      return Profile;
    case "vod":
      return vodIcon;
    case "formbuilder":
      return Formbuilder30;
    default:
      return contentDefault;
  }
};

export const replaceWithWebp = (inputString: string) => {
  const lastIndex = inputString?.lastIndexOf(".");
  if (lastIndex === -1) {
    return inputString;
  }
  return inputString?.substring(0, lastIndex) + ".webp";
};

export const getIconWithTitle = (contentList, contentType: string) => {
  const filteredData = contentList.find(
    (content) =>
      content?.isGallery && content?.id?.toLocaleLowerCase() === contentType?.toLocaleLowerCase(),
  );
  return {
    title: filteredData?.title || "",
    icon: filteredData?.icon || "",
    id: filteredData?.id || "",
  };
};
