import { Box, Typography } from "@mui/material";
import { PagesIcon, PlatFormXDateTimeFormat } from "@platformx/utilities";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "./SingleCard.style";
import { dateFormat } from "./utils/helper";

const SingleCard = ({
  title,
  published_by,
  scheduled_publish_trigger_datetime: publish,
  scheduled_unPublish_trigger_datetime: unPublish,
}: any) => {
  const date_time = PlatFormXDateTimeFormat(publish ?? unPublish);
  const classes = useCustomStyle();
  const { date, month } = dateFormat(publish ?? unPublish) || {};
  const { t } = useTranslation();
  return (
    <Box className={`${classes.schedulecardWrapper} schedulecard`}>
      <Box>
        <Typography variant='h1regular' className='day'>
          {date}
        </Typography>
        <Typography component='div' variant='h3regular' className='month'>
          {month}
        </Typography>
      </Box>
      <Box>
        <Box className='icon'>
          <img src={PagesIcon} alt='' />
        </Box>
        <Typography component='div' variant='h3semibold' className='title'>
          {title}
        </Typography>
        <Typography component='div' variant='p4medium' className='publishby'>
          {published_by}
        </Typography>
        <Typography component='div' variant='p4medium' className='datetime'>
          {publish ? t("will_publish_on") : t("will_unpublish_on")} : {date_time}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(SingleCard);
