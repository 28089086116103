/* eslint-disable @typescript-eslint/no-unused-vars */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Autocomplete, InputAdornment, Box, TextField } from "@mui/material";
import { usePlatformAnalytics, SearchIconSvg } from "@platformx/utilities";
import { FilterProps } from "../utils/prelemTypes";

const SearchBar = ({
  setValue,
  setInputValue,
  inputValue,
  suggestiveSearchList,
  handleResetInputFilter,
}: FilterProps) => {
  const url = new URL(window.location.href);
  const [handleImpression] = usePlatformAnalytics();

  const handleKeyDown = async (e) => {
    if (e.target.value !== null) {
      await url.searchParams.set("searchText", e.target.value);
      await window.history.pushState({}, "", url);
      await setValue(url.searchParams.get("searchText") as string);
    }
  };

  return (
    <Box data-testid='prelem-search-header-wrap' className='leftsearchform' component='form'>
      <Box className='searchIconBtn'>
        <img src={SearchIconSvg} alt='Search Icon' />
      </Box>
      <Autocomplete
        freeSolo
        onChange={async (event: object, newValue: string | null) => {
          if (newValue !== null) {
            await url.searchParams.set("searchText", newValue);
            await window.history.pushState({}, "", url);
            await setValue(url.searchParams.get("searchText") as string);
          }
        }}
        inputValue={inputValue}
        onInputChange={async (event, newInputValue) => {
          await url.searchParams.set("inputValue", newInputValue);
          await window.history.pushState({}, "", url);
          await setInputValue(url.searchParams.get("inputValue") as string);
          const pageDataObj = {
            eventType: "Prelem Search",
            prelemSearchText: newInputValue,
          };
          handleImpression(pageDataObj.eventType, pageDataObj);
        }}
        disableClearable
        clearIcon=''
        clearText='clear'
        id='search'
        options={suggestiveSearchList}
        popupIcon=''
        renderInput={(params) => (
          <TextField
            variant='filled'
            {...params}
            placeholder='Search'
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleKeyDown(e);
              }
            }}
            InputProps={{
              ...params.InputProps,
              type: "search",
              endAdornment: (
                <InputAdornment position='start' sx={{ cursor: "pointer" }}>
                  {inputValue && <CloseRoundedIcon onClick={() => handleResetInputFilter()} />}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBar;
