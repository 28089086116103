/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */

import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { useUserSession } from "@platformx/utilities";
import MenuSitesListDropdown from "../../../../components/MenuSitesListDropdown/MenuSitesListDropdown";
import { useDynamicRoutes } from "../../../../hooks/useDynamicRoutes/useDynamicRoutes";
import MenuItems from "./MenuItems";

export default function Menu(props) {
  const [getSession] = useUserSession();
  const { generatedMenuItems } = useDynamicRoutes();
  const [dynamicMenu, setDynamicMenu] = useState<any>([]);
  const { permissions } = getSession();
  const filtered = permissions
    ?.map((val) => val?.category?.toLowerCase())
    ?.filter((val, index, arr) => arr.indexOf(val) === index);

  useEffect(() => {
    setDynamicMenu(generatedMenuItems);
  }, [generatedMenuItems]);

  return (
    <Box className='menulist'>
      <MenuSitesListDropdown />

      {dynamicMenu?.map((val, index) => {
        const isShow = filtered?.includes(val?.title?.toLowerCase());
        return (
          isShow && (
            <MenuItems
              key={index}
              Title={val?.id}
              MenuName={val?.menu}
              open={props.open}
              handleMenuclose={props.handleMenuclose}
              handleMenuAction={props.handleMenuAction}
            />
          )
        );
      })}
    </Box>
  );
}
