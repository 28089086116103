// App.js
import React, { useState } from "react";
import { Menu, MenuItem, ListItemText, Typography, Box } from "@mui/material";
import { FilterIcon } from "@platformx/utilities";
import { t } from "i18next";
import useDamContent from "./DamContent.style";

export const SortByMenu = ({ sortBy, setSortBy }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useDamContent();
  const [items] = useState([
    { text: "Newest", val: "DESC" },
    { text: "Oldest", val: "ASC" },
  ]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const value = items.find((obj) => obj.val === sortBy);

  return (
    <Box className={classes.filtermenu}>
      <Box
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className={classes.filterbox}
        id='demo-customized-button'>
        <img src={FilterIcon} alt='img' />
        <Typography variant='p3regular' className={classes.typoTextn}>
          {t("sort_by_relevant")} {value?.text}
        </Typography>
      </Box>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : undefined,
            minWidth: "120px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <Box className={classes.menuitembox}>
          {items.map((item, index) => (
            <MenuItem
              key={index}
              className={classes.menuItemhover}
              onClick={() => {
                setSortBy(item.val);
                handleClose();
              }}
              data-testid='sort-by-menu'>
              <ListItemText primary={<Typography variant='p2regular'>{item.text}</Typography>} />
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};
