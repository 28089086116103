import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import { CustomSearch } from "@platformx/utilities";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAssetFilter } from "./AssetFilter.style";

export default function DamContentSearchBox({ onSearch, searchCloseToggle, assetType }: any) {
  const { t } = useTranslation();
  const classes = useAssetFilter();

  const [searchQuery, setSearchQuery] = React.useState("");
  const onEnter = (e) => {
    if (e.keyCode === 13) {
      const searchString = searchQuery.trim().replaceAll("  ", " ").split(" ").join(" OR ");
      onSearch(searchString);
    }
  };

  const handleOnChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const resetSearch = () => {
    setSearchQuery("");
    onSearch("");
    searchCloseToggle && searchCloseToggle();
  };

  const searchText =
    assetType === "Image" ? `${t("search")} ${t("images")}` : `${t("search")} ${t("videos")}`;

  return (
    <FormControl className={classes.contentcontrol}>
      <TextField
        autoComplete='off'
        // autoFocus
        variant='filled'
        placeholder={searchText}
        value={searchQuery}
        onChange={handleOnChange}
        onKeyDown={onEnter}
        InputProps={{
          endAdornment: (searchQuery || searchCloseToggle) && (
            <IconButton onClick={resetSearch} data-testid='dam-content-reset-search'>
              <CloseRoundedIcon />
            </IconButton>
          ),
          startAdornment: (
            <InputAdornment position='start'>
              <img src={CustomSearch} alt='search' className={classes.inputadorbox} />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
