import { gql } from "@apollo/client";

export const schemaQueries = {
  CONTENT_TYPE_SCHEMA: gql`
    query {
      authoring_getDocument(type: "document")
    }
  `,
  FETCH_SCHEMA: gql`
    query {
      authoring_getSchema(type: "document", filter: "ALL")
    }
  `,
  CONTENT_SCHEMA: gql`
    query GetSchema($filter: String) {
      authoring_getSchema(type: "document", filter: $filter)
    }
  `,
  CONTENT_SCHEMA_WITH_DATA: gql`
    query GetSchemaWithData($contentType: String, $name: String) {
      authoring_getSchemaContent(contentType: $contentType, name: $name)
    }
  `,
};
