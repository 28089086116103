/* eslint-disable no-shadow */
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { RootState } from "@platformx/authoring-state";
import {
  BasicSwitch,
  EditsquareIcon,
  ShowToastSuccess,
  usePlatformAnalytics,
} from "@platformx/utilities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { useSelector } from "react-redux";
import BackButton from "../BackButton/BackButton";
import "../PageSettings/PageSettings.css";
import { PrelemSeoProps, SeoInfo } from "../utils/editTypes";
import { useCustomStyle } from "./PrelemSEO.style";

const PrelemSEO: React.FC<PrelemSeoProps> = ({ setPageId, selectedPrelemIndex, handleSave }) => {
  const classes = useCustomStyle();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { page } = useSelector((state: RootState) => state);
  const { SeoEnabled, StructuredData } = page.prelemMetaArray[selectedPrelemIndex];
  const { t } = useTranslation();
  const [seoInfo, setSeoInfo] = useState<SeoInfo>({
    showContent: SeoEnabled,
    share: false,
    structureData:
      StructuredData && typeof StructuredData === "string" ? JSON.parse(StructuredData) : {},
  });
  const [seoInstance, setSeoInstance] = useState<SeoInfo>({
    showContent: SeoEnabled,
    share: false,
    structureData:
      StructuredData && typeof StructuredData === "string" ? JSON.parse(StructuredData) : {},
  });

  const handleToggleChange = (event, fieldType) => {
    const contentNew = { ...seoInfo };
    contentNew[fieldType] = event.target.checked;
    setSeoInfo(contentNew);
  };

  const [handleImpression] = usePlatformAnalytics();
  const saveSeoInfo = () => {
    handleSave("StructuredData", JSON.stringify(seoInfo.structureData));
    handleSave("SeoEnabled", seoInfo.showContent);
    const pageDataObj = {
      eventType: "Prelem SEO Setting Saved",
      SeoPrelemSaved: true,
    };
    handleImpression(pageDataObj.eventType, pageDataObj);
    ShowToastSuccess(`${t("prelem_seo_info_toast")} ${t("saved_toast")}`);
  };

  const handleStructureData = (event, fieldType) => {
    const contentNew = { ...seoInfo };
    contentNew[fieldType] = event.jsObject;
    setSeoInfo(contentNew);
  };

  const toggleStructureData = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const { SeoEnabled, StructuredData } = page.prelemMetaArray[selectedPrelemIndex];
    setSeoInstance({
      showContent: SeoEnabled,
      share: false,
      structureData:
        StructuredData && typeof StructuredData === "string" ? JSON.parse(StructuredData) : {},
    });
  }, [selectedPrelemIndex, page]);

  const copyStructureData = () => {
    navigator.clipboard.writeText(JSON.stringify(seoInfo.structureData, undefined, 2));
    ShowToastSuccess(`${t("page_structure_data")} ${t("copied")}`);
  };

  useEffect(() => {
    setSeoInfo(seoInstance);
  }, [seoInstance]);

  return (
    <Box className={`${classes.prelemSEOWrapper} pageSettingmainWp prelemSEOWrapperBg`}>
      <BackButton setPageId={setPageId} Title={t("prelem_seo")} backTo='prelemSetting' />
      <Box className='rowBox'>
        <Typography className='switchbox' variant='p4regular'>
          {t("page_prelem_find")}
          <BasicSwitch
            checked={seoInfo?.showContent}
            onChange={(e: any) => handleToggleChange(e, "showContent")}
            color={theme.palette.customColor.PRIMARY}
            bgcolor={theme.palette.customColor.PLACEHOLDER}
          />
        </Typography>
      </Box>
      <Box className='rowBox'>
        <Typography className='switchbox' variant='p4regular'>
          {t("page_prelem_share")}
          <BasicSwitch
            disabled
            checked={seoInfo?.share}
            onChange={(e: any) => handleToggleChange(e, "share")}
            color={theme.palette.customColor.PRIMARY}
            bgcolor={theme.palette.customColor.PLACEHOLDER}
          />
        </Typography>
      </Box>
      <Box className='rowBox'>
        <Typography className='switchbox' variant='p4regular'>
          {t("page_structure_data")}
          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.customColor.PRIMARY,
              textTransform: "capitalize",
              cursor: "pointer",
              pointerEvents: Object.keys(seoInfo.structureData).length !== 0 ? "visible" : "none",
              opacity: Object.keys(seoInfo.structureData).length !== 0 ? 1 : 0.3,
            }}
            onClick={toggleStructureData}>
            <img
              src={EditsquareIcon}
              alt='icon'
              className='icon'
              style={{
                marginRight: "5px",
                filter:
                  Object.keys(seoInfo.structureData).length !== 0
                    ? `brightness(0) contrast(100%)`
                    : `filter: grayscale(100%)`,
              }}
            />
            {t("edit")}
          </span>
        </Typography>
      </Box>
      {isOpen && (
        <Box className='prelemSEOStructureDataOuter'>
          <Box className='prelemSEOStructureDataInner'>
            <Box className='buttonWrapper'>
              <ContentCopyIcon onClick={copyStructureData} sx={{ cursor: "pointer" }} />
              <CheckIcon onClick={toggleStructureData} sx={{ cursor: "pointer" }} />
            </Box>
            <JSONInput
              id='json-editor'
              confirmGood={false}
              placeholder={seoInfo.structureData}
              onChange={(e) => handleStructureData(e, "structureData")}
              theme='light_mitsuketa_tribute'
              locale={locale}
              height='95%'
              width='100%'
              colors={{
                string: "#1984bc",
                keys: theme.palette.customColor.PRIMARY,
                colon: theme.palette.customColor.PRIMARY,
                default: theme.palette.customColor.PRIMARY,
              }}
              style={{
                body: { fontSize: "20px" },
              }}
            />
          </Box>
        </Box>
      )}
      <Box className='rowBox'>
        <Button
          variant='primaryButton'
          disabled={JSON.stringify(seoInstance) === JSON.stringify(seoInfo)}
          sx={{ width: "100%" }}
          onClick={saveSeoInfo}
          data-testid='save-seo-info1'>
          {t("done")}
        </Button>
      </Box>
    </Box>
  );
};
export default PrelemSEO;
