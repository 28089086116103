import {
  PageHiddenIcon,
  InfoIcon,
  EditsquareIcon,
  VisibilityIcon,
  SettingsIcon,
  PageCopyIcon,
  ArrowupIcon,
  ArrowdownIcon,
  RefreshIcon,
  DeleteIcon,
} from "@platformx/utilities";

export const iconsSet = [
  {
    id: "prelemInfo",
    tooltipId: "Prelem Info",
    Icon: () => <img src={InfoIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={InfoIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "edit",
    tooltipId: "Edit",
    Icon: () => <img src={EditsquareIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={EditsquareIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "show",
    tooltipId: "Hide",
    Icon: () => <img src={VisibilityIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={VisibilityIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "hide",
    tooltipId: "Show",
    Icon: () => <img src={PageHiddenIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={PageHiddenIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "prelemSetting",
    tooltipId: "Settings",
    Icon: () => <img src={SettingsIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={SettingsIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "copy",
    tooltipId: "Duplicate",
    Icon: () => <img src={PageCopyIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={PageCopyIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "up",
    tooltipId: "Move Up",
    Icon: () => <img src={ArrowupIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={ArrowupIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "down",
    tooltipId: "Move Down",
    Icon: () => <img src={ArrowdownIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={ArrowdownIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "reset",
    tooltipId: "Undo",
    Icon: () => <img src={RefreshIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={RefreshIcon} alt='icon' className='hideIcon' />,
  },
  {
    id: "delete",
    tooltipId: "Delete",
    Icon: () => <img src={DeleteIcon} alt='icon' className='icon' />,
    disabledIcon: () => <img src={DeleteIcon} alt='icon' className='hideIcon' />,
  },
];
