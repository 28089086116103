import { Box, Typography, useTheme } from "@mui/material";
import { createImageURL } from "@platformx/utilities";
import CardListStyle from "./CardList.styles";

const Card = ({ key, layout, handleLayoutFilter }) => {
  const classes = CardListStyle();
  const theme = useTheme();
  const LayoutThumbnailURL = createImageURL(
    layout?.thumbnail?.ImageCropUrl,
    layout?.thumbnail?.ext,
  );
  return (
    <Box
      key={key}
      className={classes.cardwp}
      sx={{
        backgroundColor: `${layout?.selectedValue ? theme?.palette?.customColor?.INPUT : ""}`,
      }}
      onClick={() => handleLayoutFilter(layout?.mapping, layout?.id)}
      data-testid='layout-image-item'>
      <Box className={classes.img}>
        <img
          src={`${LayoutThumbnailURL}`}
          srcSet={`${LayoutThumbnailURL}`}
          alt={`${layout?.title}`}
          loading='lazy'
        />
      </Box>
      <Typography className={`${classes.textTypo} textcolor`} variant='p4regular'>
        {layout.title}
      </Typography>
    </Box>
  );
};

export default Card;
