import Menu from "@mui/material/Menu";
import { FormControl, Radio, RadioGroup, Typography, FormControlLabel } from "@mui/material";
import * as React from "react";
import { capitalizeFirstLetter } from "@platformx/utilities";
import { useFilterDropdownStyles } from "./FilterDropdown.style";

// do not change below array values, it will affect functionalities
const label = ["default", "folder", "tag", "author"];

export function FilterDropdown({ setFilter, anchorE2, setAnchorE2, filter, setSearchQuery }) {
  const open = Boolean(anchorE2);
  const classes = useFilterDropdownStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery("");
    setFilter(event.target.value);
    setAnchorE2(null);
  };

  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={anchorE2}
      open={open}
      onClose={() => setAnchorE2(null)}
      className={`${classes.FilterMenuWrapperItem} filterMenu`}
      PaperProps={{
        className: "filterMenuItem",
        // elevation: 0,
      }}>
      <FormControl>
        <RadioGroup
          value={filter}
          onChange={handleChange}
          sx={{ textTransform: "capitalize" }}
          className='listViewContainer'>
          {label?.map((obj) => (
            <FormControlLabel
              key={obj}
              className={`${filter === obj ? "active" : ""} listView`}
              value={obj}
              control={<Radio sx={{ display: "none" }} />}
              label={
                <Typography className={classes.filterdropdowntypo} variant='p2regular'>
                  {capitalizeFirstLetter(obj)}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Menu>
  );
}
