import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  topLeftCta: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.customColor.PRIMARY,
    },
    "& svg": {
      fontSize: "28px",
      marginRight: "2px",
      marginLeft: "-10px",
    },
  },
}));
