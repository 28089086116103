import { Box, IconButton, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../assets/svg//deleteIcon.svg";
import EditIcon from "../../assets/svg//editIcon.svg";
import {
  CATEGORY_CONTENT,
  CATEGORY_PAGE,
  SITE_SETTING,
  SUB_CATEGORY_PAGE,
} from "../../constants/CommonConstants";
import { ErrorTooltip } from "../ErrorTooltip/ErrorTooltip";
import { SYSTEM_TAGS } from "./constants";

const CardOption = (props: any) => {
  const { t } = useTranslation();

  const {
    dataList = {},
    tagName = "",
    handleEdit = () => {},
    canAccessAction,
    handleDeleteButton = () => {},
  } = props;

  const hasAccess = (action) => {
    switch (tagName) {
      case "tagscategories":
        return dataList.type === SYSTEM_TAGS ? false : canAccessAction(SITE_SETTING, "tag", action);
      case "courses":
        return false;
      case "sitepage":
        return canAccessAction(CATEGORY_PAGE, SUB_CATEGORY_PAGE, action);
      default:
        // for "article", "quiz", "poll", "event", "vod"
        return canAccessAction(CATEGORY_CONTENT, tagName, action);
    }
  };

  return (
    <Box className='d-flex align-items-center'>
      {tagName !== "sitepage" ||
      (tagName === "sitepage" &&
        (dataList?.scheduledPublishTriggerDateTime === null ||
          dataList?.scheduledPublishTriggerDateTime === "") &&
        (dataList?.scheduledUnPublishTriggerDateTime === null ||
          dataList?.scheduledUnPublishTriggerDateTime === "")) ? (
        <ErrorTooltip
          component={
            <MenuItem
              className='icons'
              disableRipple
              onClick={handleEdit}
              disabled={!hasAccess("Update")}>
              <IconButton className='hoverIcon' data-testid='edit'>
                <img src={EditIcon} alt='edit' />
              </IconButton>
            </MenuItem>
          }
          tooltipMsg={dataList.type === SYSTEM_TAGS ? t("cannot_edit_tag") : ""}
          doAccess={!hasAccess("Update")}
        />
      ) : null}
      {tagName !== "sitepage" ||
      (tagName === "sitepage" &&
        (dataList?.scheduledPublishTriggerDateTime === null ||
          dataList?.scheduledPublishTriggerDateTime === undefined) &&
        (dataList?.scheduledUnPublishTriggerDateTime === null ||
          dataList?.scheduledUnPublishTriggerDateTime === undefined)) ? (
        <ErrorTooltip
          component={
            <MenuItem
              className='icons'
              disableRipple
              onClick={handleDeleteButton}
              disabled={!hasAccess("Delete")}>
              <IconButton className='hoverIcon' data-testid='delete'>
                <img src={DeleteIcon} alt='delete' />
              </IconButton>
            </MenuItem>
          }
          tooltipMsg={dataList.type === SYSTEM_TAGS ? t("cannot_delete_tag") : ""}
          doAccess={!hasAccess("Delete")}
        />
      ) : null}
    </Box>
  );
};

export default CardOption;
