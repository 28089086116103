import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "@platformx/authoring-state";
import { iconMap } from "../../../../../utils/constant";

const TextFieldBox = styled("div")({
  position: "relative",
  width: "100%",
  "& input": {
    paddingLeft: "40px",
  },
});

const ListContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "5px 10px",
});
export default function AutoCompleteSearch({
  handleSearchKeyword,
  handleSearch,
  loading,
  autoCompleteData,
}) {
  const { t } = useTranslation();
  const [, setKeyword] = useState("");
  const renderGroup = (params: any) => {
    const { children, group } = params;
    return (
      <Grid container alignItems='center' paddingLeft={2}>
        <Grid item>
          <Typography variant='subtitle1'>{group}</Typography>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const handleKeyDown = (e) => {
    const { value = "" } = e?.target;
    if (value !== null && ("" + value).length > 0) {
      handleSearchKeyword(value);
      setKeyword(value);
      handleSearch({
        tags: [],
        title: "",
        author: "",
        fromDate: "",
        toDate: "",
      });
    }
  };
  const { routes = [] } = useSelector((state: RootState) => state?.routes);
  const contentTypeList = routes?.filter((route) => route?.isGallery);
  const placeHolderText = `${t("search_for")}“${t("pages")}” / “${t("articles")}” / “${t("quiz")}” / “${t("polls")}” / “${t("events")}“`;
  return (
    <Autocomplete
      fullWidth
      freeSolo
      sx={{
        "& .Platform-x-OutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      options={autoCompleteData || []}
      groupBy={(option: any) => option.ContentType || ""}
      getOptionLabel={(option) => option?.Title || ""}
      isOptionEqualToValue={(option, value) => option?.Title === value?.Title}
      onInputChange={(event, newInputValue) => {
        handleSearchKeyword(newInputValue);
      }}
      renderInput={(params) => (
        <TextFieldBox>
          <Box className='searchIconPos'>
            <SearchIcon />
          </Box>
          <TextField
            {...params}
            placeholder={placeHolderText}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
            }}
            // onChange={(e) => handleSuggestions(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleKeyDown(e);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </TextFieldBox>
      )}
      renderGroup={renderGroup}
      renderOption={(props, option: any) => {
        return (
          <div>
            <li {...props}>
              <ListContainer>
                {iconMap[option.ContentType]}
                {/* <img src={getIconWithTitle(contentTypeList, option.ContentType)?.icon} alt='' /> */}
                <Typography variant='h5regular' sx={{ marginLeft: "8px" }}>
                  {option.Title}
                </Typography>
              </ListContainer>
            </li>
          </div>
        );
      }}
    />
  );
}
