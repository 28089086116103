import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  editMainBox: {
    position: "relative",
  },
  editPageComonBox: {
    position: "relative",
    outline: "1px solid transparent",
    marginBottom: "1px",
    background: theme.palette.customColor.BGLIGHT,
    "&:hover": {
      outlineColor: theme.palette.customColor.TERTIARY,
    },
  },
  prelemButtonsWp: {
    background: theme.palette.customColor.PRIMARY,
    position: "absolute",
    top: 0,
    left: 0,
    color: theme.palette.customColor.BGLIGHT,
    zIndex: 999,
    [theme.breakpoints.down("em")]: {
      left: "auto",
      right: 0,
      top: "10px",
    },
    "& ul": {
      padding: 0,
      margin: 0,
      display: "flex",
      [theme.breakpoints.down("em")]: {
        flexDirection: "column",
      },
      "& li": {
        margin: "5px 3px",
        display: "flex",
        cursor: "pointer",
        [theme.breakpoints.down("em")]: {
          margin: "2px",
        },
        "& img": {
          filter: "brightness(0) invert(1)",
        },
        "&:hover img, .hideIcon": {
          filter: "brightness(0) invert(0.6)",
        },
        "&:hover": {
          color: theme.palette.customColor.TERTIARY,
        },
      },
    },
  },
  bottomaddCtaWp: {
    position: "absolute",
    left: 0,
    bottom: "-40px",
    width: "100%",
    justifyContent: "center",
    height: "40px",
    zIndex: 99,
    "& button": {
      minWidth: "159px",
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      height: "40px",
      textTransform: "uppercase",
    },
  },
  topaddCtaWp: {
    position: "absolute",
    left: 0,
    top: "-38px",
    width: "100%",
    justifyContent: "center",
    zIndex: 99,
    height: "40px",
    "& button": {
      minWidth: "159px",
      margin: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      textTransform: "uppercase",
    },
  },
  previewPagePrelem: {
    "& $prelemButtonsWp": {
      display: "none",
    },
    "& $topaddCtaWp": {
      display: "none !important",
    },
    "& $bottomaddCtaWp": {
      display: "none !important",
    },
  },
}));
