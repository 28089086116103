import EditIcon from "@mui/icons-material/Edit";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import Box from "@mui/material/Box";
import ButtonEditLinkStyle from "./ButtonEditLink.styles";

interface ButtonEditLinkProps {
  zIndex: number;
  opacity: string;
  left: string;
  top: string;
  handleEditButtonWindowRef: (clientX: number, clientY: number) => void;
  handleEditButtonText: (e: object, buttonName: string) => void;
  buttonRef: any;
  buttonName: string;
}

const ButtonEditLink = ({
  zIndex,
  opacity,
  left,
  top,
  handleEditButtonWindowRef,
  buttonRef,
  handleEditButtonText,
  buttonName,
}: ButtonEditLinkProps) => {
  const classes = ButtonEditLinkStyle();
  return (
    <Box
      className={classes.buttonEditLinkWp}
      sx={{
        opacity: opacity,
        top: top,
        zIndex: zIndex,
        left: parseInt(left) >= 1000 ? `${parseInt(left) - 200}px` : left,
      }}
      data-testid='button-edit-link'
      ref={buttonRef}>
      <EditIcon className='editicon' onClick={(e) => handleEditButtonText(e, buttonName)} />
      <InsertLinkRoundedIcon
        className='insertLink'
        onClick={(e) => handleEditButtonWindowRef(e.clientX, e.clientY)}
      />
    </Box>
  );
};
export default ButtonEditLink;
