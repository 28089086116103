import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const SearchPrelemStyle = makeStyles((theme: Theme) => ({
  leftTop: {
    padding: "15px",
    "& .textLight": {
      color: theme.palette.customColor.LABEL,
      margin: "10px 0 14px 0",
    },
    "& .leftsearchform": {
      position: "relative",
      "& .searchIconBtn": {
        position: "absolute",
        zIndex: 1,
        top: 0,
        bottom: 0,
        left: "10px",
        cursor: "pointer",
        width: "30px",
        height: "30px",
        margin: "auto",
      },
      "& input": {
        paddingLeft: "45px",
      },
    },
  },
  leftBottom: {
    height: "calc(100vh - 260px)",
    overflowY: "auto",
    overflowX: "hidden",
    borderTop: `1px solid ${theme.palette.customColor.LINE}`,
    borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflowY: "hidden",
      overflowX: "auto",
      borderTop: `1px solid ${theme.palette.customColor.LINE}`,
      borderRight: "0",
      borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
      display: "flex",
      paddingBottom: "10px",
    },
  },
}));
export default SearchPrelemStyle;
