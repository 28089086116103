import LoadingButton from "@mui/lab/LoadingButton";
import { Box, ButtonProps as MuiButtonProps, styled } from "@mui/material";

// Only include variant, size, and color
type ButtonBaseProps = Pick<MuiButtonProps, "variant">;

export interface ButtonProps extends ButtonBaseProps {
  label?: string;
  variant:
    | "primaryButton"
    | "secondaryButton"
    | "primaryButtonLight"
    | "secondaryButtonLight"
    | "tertiaryButton"
    | "quaternaryButton";

  Icon: any;
  disabled?: boolean;
  onClick?: any;
  className?: string;
  style?: any;
}
const StyledXHoverTextButton = styled(LoadingButton)(({ theme }) => ({
  fontFamily: theme?.fontFamily?.primary,
  "&.onhoverbutton": {
    minWidth: "auto",
    transition: "width 2s",
    padding: "12px",
    display: "flex",
    "& span": {
      margin: 0,
    },
    "& .buttonLabel": {
      transition: "width 2s",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 0,
      marginLeft: 0,
    },
    "&:hover .buttonLabel": {
      width: "auto",
      transition: "width 2s",
      marginLeft: "8px",
      marginRight: "8px",
    },
  },
}));
export const XHoverTextButton = ({
  label,
  variant,
  Icon,
  disabled,
  className,
  onClick,
  style,
  ...rest
}: ButtonProps) => (
  <>
    {/* <style>{fontStyle}</style> */}
    <StyledXHoverTextButton
      variant={variant}
      startIcon={Icon}
      disabled={disabled}
      onClick={onClick}
      className={`${className} onhoverbutton`}
      style={style}
      {...rest}>
      <Box className='buttonLabel'>{label}</Box>
    </StyledXHoverTextButton>
  </>
);
