import { Box, Grid } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LanguageDropDown, MenuIcon, getSelectedSite } from "@platformx/utilities";
import SearchBox from "../SearchBox/SearchBox";
import "./Header.css";

import { useStyles } from "./Header.styles";
import NotificationBox from "./NotificationBox";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isSideBar?: boolean;
}
const DrawerWidth = 250;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, isSideBar }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(!open && {
    ...(isSideBar
      ? {
          [theme.breakpoints.up("sm")]: {
            marginLeft: "65px",
            width: "calc(100% - 65px)",
          },
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        }
      : {
          [theme.breakpoints.up("sm")]: {
            marginLeft: "0",
            width: "100%",
          },
        }),
  }),
  ...(open && {
    marginLeft: DrawerWidth,
    width: `calc(100% - ${DrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
  }),
}));

const Header = ({
  open,
  handleDrawerOpen,
  isSideBar,
  hasSearch = true,
  hasLogo = false,
  menuItemSelected,
}) => {
  const classes = useStyles();
  const isSiteSystem = getSelectedSite()?.toLowerCase() === "system";
  const theme = useTheme();
  const pageUrl = new URL(window.location.href);
  const ifTab = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <AppBar position='sticky' className='topHeader' open={open} isSideBar={isSideBar}>
      <Grid container>
        <Grid item xs={10} className={classes.searchContainer}>
          {!hasSearch && pageUrl.pathname.split("/")[3] !== "" && (
            <Box className='menuIcon' onClick={handleDrawerOpen} data-testid='openDrawer'>
              <img alt='settings' src={MenuIcon} />
            </Box>
          )}

          {hasSearch && (
            <>
              <Box className='menuIcon' onClick={handleDrawerOpen} data-testid='drawerOpen'>
                <img alt='settings' src={MenuIcon} />
              </Box>
              {!isSiteSystem && <SearchBox ifTab={ifTab} menuItemSelected={menuItemSelected} />}
            </>
          )}
        </Grid>
        <Grid item xs={2} className='d-flex alignitemscenter justify-content-end'>
          <Box className='d-flex alignitemscenter justify-content-end'>
            <LanguageDropDown />
            <NotificationBox />
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
