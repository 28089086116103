import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const CardListStyle = makeStyles((theme: Theme) => ({
  cardwp: {
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
    borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
    width: "50%",
    padding: "9px",
    marginBottom: "0px",
    minHeight: "97px",
    float: "left",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minWidth: "33.33%",
      maxWidth: "33.33%",
      padding: "5px",
    },
    "&:nth-child(2n)": {
      borderRight: "0",
      [theme.breakpoints.down("sm")]: {
        borderRight: `1px solid ${theme.palette.customColor.LINE}`,
      },
    },
  },
  img: {
    display: "flex",
    marginBottom: "9px",
    alignItems: "center",
    justifyContent: "center",
    width: "100px",
    height: "48px",
    overflow: "hidden",
    "& img": {
      maxWidth: "100%",
      height: "48px",
    },
  },

  textTypo: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    "&.textcolor": {
      color: theme.palette.customColor.PRIMARY,
    },
  },
}));
export default CardListStyle;
