import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  containerStyle: {
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: theme.palette.customColor.LIGHT,
    width: "100%",
    height: "100%",
    zIndex: "999",
  },
  logoDispaly: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  headerTextDispaly: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.up("xs")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  contentStyle: {
    [theme.breakpoints.up("xs")]: {
      paddingRight: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: "10px",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "45px",
    },
    display: "flex",
    flexDirection: "row",
    background: theme.palette.customColor.LIGHT,
    alignItems: "center",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    borderRadius: theme.borderRadius.value,
    // width: '389px',
    height: "50px",
    marginTop: "15px",
    position: "relative",
  },
  imgUploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: `${theme.borderRadius.value} 0px 0px ${theme.borderRadius.value}`,
    width: "89px",
    height: "-webkit-fill-available",
    backgroundColor: theme.palette.customColor.BGWEAK,
    cursor: "pointer",
    overflow: "hidden",
    "& picture": {
      minHeight: "48px",
    },
  },
  urlEllipisis: {
    paddingLeft: "14px",
    width: "calc(100% - 100px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },

  heroBannerCloudIconBox: {
    width: "20px",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIconStyle: {
    position: "absolute",
    right: 0,
    padding: "5px 10px 5px 5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  workflowIconContainer: {
    display: "flex",
    padding: "10px",
  },
  workflowIconActive: {
    filter: "brightness(0) invert(5%)",
    height: "24px",
  },
  workflowIconInactive: {
    filter: "brightness(0) invert(50%)",
    height: "24px",
  },
  buttonWrapper: {
    "& .iconBtn": {
      minWidth: "0px",
      display: "flex",
      justifyContent: "center",
      width: "38px",
      height: "38px",
      alignItems: "center",
      margin: "0 1px",
      "& span": {
        margin: 0,
      },
      "&:hover": {
        backgroundColor: "rgba(20, 20, 43, 0.04)",
      },
      "&:disabled": {
        color: theme.palette.customColor.PLACEHOLDER,
        pointerEvents: "auto",
      },
    },
  },
  hoverCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "89px",
    height: "-webkit-fill-available",
    backgroundColor: "transparent",
    cursor: "pointer",
    position: "absolute",
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
  },
  spacingLeftRight: {
    paddingLeft: "24px",
    paddingRight: "24px",
    gap: "10px",
  },
}));
