import { Box, Grid, Button } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import PrelemSearchHeaderStyle from "./PrelemSearchHeader.styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTranslation } from "react-i18next";

const PrelemSearchHeader = () => {
  const classes = PrelemSearchHeaderStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box className={classes.prelemsearchheader}>
      <Grid container>
        <Grid item xs={2} md={2} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              const path = localStorage.getItem("path");
              if (path) {
                navigate({
                  pathname: "/edit-page",
                  search: `?${createSearchParams({
                    page: path.toString(),
                  })}`,
                });
              } else {
                console.error("path from localstorage is empty in PrelemSearchHeader.tsx");
              }
            }}>
            <KeyboardBackspaceIcon />
          </Box>
        </Grid>
        <Grid item xs={10} md={10} className='d-flex alignitemscenter justify-content-end'>
          <Box className='d-flex alignitemscenter justify-content-end'>
            <Button
              variant='secondaryButton'
              className='sm'
              onClick={() => {
                const path = localStorage.getItem("path");
                if (path) {
                  navigate({
                    pathname: "/edit-page",
                    search: `?${createSearchParams({
                      page: path.toString(),
                    })}`,
                  });
                } else {
                  console.error("path from localstorage is empty in PrelemSearchHeader.tsx");
                }
              }}>
              {t("cancel")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrelemSearchHeader;
