/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery } from "@apollo/client";
import { CloseOutlined, InfoOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  addPrelemFunc,
  fetchPrelemContent,
  fetchPrelemValidation,
} from "@platformx/authoring-apis";
import { RootState } from "@platformx/authoring-state";
import { createImageURL, usePlatformAnalytics } from "@platformx/utilities";
import { Mapping } from "@platformx/prelems";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrelemListStyle from "./PrelemList.styles";
// import { navigateToEditpage } from "../utils/helper";

//mapping dynamic instance
const mappingDynamicInstance = {};
Object.keys(Mapping).forEach((item) => {
  mappingDynamicInstance[item] = React.lazy(() =>
    import(`@platformx/prelems`).then((module) => ({
      default: module[Mapping[item]],
    })),
  );
  return mappingDynamicInstance;
});

const PrelemBox = ({ item }) => {
  const classes = PrelemListStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useSelector((state: RootState) => state);
  const [showInfo, setShowInfo] = useState(false);
  const [runFetchValidationQuery] = useLazyQuery(fetchPrelemValidation);
  const [runFetchContentQuery] = useLazyQuery(fetchPrelemContent);
  const [handleImpression] = usePlatformAnalytics();

  const handleClick = () => {
    setShowInfo((prev) => !prev);
  };

  const onClickAddPrelem = () => {
    const addPrelemObj = {
      eventType: "Add Prelem",
      clickType: "Add Prelem Click on Search Page",
      PrelemId: item?.PrelemId,
      PrelemName: item?.PrelemName,
      Tags: item?.Tags,
    };
    handleImpression(addPrelemObj.eventType, addPrelemObj);
    addPrelemFunc(
      dispatch,
      item,
      runFetchContentQuery,
      runFetchValidationQuery,
      navigate,
      page?.insertPrelemAt,
    );
  };

  const PreviewThumbnailURL = createImageURL(item?.PreviewThumbnail, item?.Thumbnail?.Ext);
  return (
    <Box className={classes.prelemboxwp}>
      <img src={PreviewThumbnailURL} alt='green iguana' />
      <Box className={classes.iButton}>
        <IconButton onClick={handleClick} classes={classes.iconButton}>
          {!showInfo ? <InfoOutlined fontSize='small' /> : <CloseOutlined fontSize='small' />}
        </IconButton>
      </Box>

      <Box className={classes.overlay} sx={{ opacity: showInfo ? 1 : 0 }}>
        <Box className={classes.contentwp}>
          <Typography variant='h5bold' mb='10px'>
            {item?.PrelemName}
          </Typography>
          <Typography variant='p4regular' className={classes.linetrancate2line}>
            {item?.Description}
          </Typography>
          <Box className={classes.buttonswp}>
            <Button
              variant='secondaryButtonLight'
              className='sm'
              style={{ marginRight: "10px" }}
              onClick={() => {
                const pageDataObj = {
                  eventType: "Prelem Preview",
                  PrelemPreview: true,
                  PrelemPreviewId: item?.PrelemId,
                  PrelemPreviewName: item?.PrelemName,
                };
                handleImpression(pageDataObj.eventType, pageDataObj);
                navigate("./preview", {
                  state: item,
                });
              }}
              data-testid='preview-button'>
              {t("preview")}
            </Button>
            <Button
              variant='primaryButtonLight'
              className='sm'
              onClick={onClickAddPrelem}
              data-testid='add-prelem-button'>
              {t("add_prelem")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrelemBox;
